import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './moreInfo.style.scss';
import { appState } from '@state/index';
import {
 icon01,
 icon02,
 icon03,
 icon04,
} from './assets';
export type Props = {

};

export type CardProps = {
    image: string;
    title: string;
    content: string;
};
const Card = (props: CardProps) => (
    <div className={style.card}>
        <div className={style.imgContainer}>
            <img src={props.image} className={style.image} />
        </div>
        <div className={style.title}>{props.title}</div>
        <div className={style.content}>{props.content}</div>
    </div>
);
@observer
export default class MoreInfoComponent extends Component<Props> {
    render() {
        return (
            <div className={style.container}>
                <Card
                    image={icon01}
                    title={'Why should I invest in a smart portfolio?'}
                    content={'Our investments are based on the Nobel Prize-winning model, tailored specifically to the Israeli investor.'}
                    />
                <Card
                    image={icon02}
                    title={'How much does management fees pay?'}
                    content={'Our management fees are fair and transparent. The management fee rate is decreasing as the portfolio grows - and the downward update is done automatically, without having to contact us. We also calculate the management fees on a daily basis so that our calculation is accurate.'}
                    />
                <Card
                    image={icon03}
                    title={'How and when will I receive activity reports?'}
                    content={'With us, it is very easy to keep up with every step, from your computer or mobile - what your money has done, what actions we have carried out in the portfolio, how much management fees you paid and how much you saved on management fees in relation to what is customary in the market. Of course we will update you on periodic reports under the law.'}
                    />
                <Card
                    image={icon04}
                    title={'How and when can I withdraw money?'}
                    content={'The bag can be requested at any time and we will deal with it as soon as possible. We will sell the securities in the portfolio in accordance with market conditions and upon receipt for sale the money will be able to withdraw. For the most part, the sales process takes place quickly.'}
                    />
            </div>
        );
    }
}
