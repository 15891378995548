import React, {  Component, Fragment } from 'react';
import style from './pieChart.style.scss';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Text, Label } from 'recharts';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { appState } from '@state/index';

export type Props = {
    data: {[fieldName: string]: number} | any,
    title: string;
    className?: string | string[];
    placeholder1: string;
    placeholder2: string;
};
const colors = [
    '2AD7BA',
    '1D255E',
    '1C8FFF',
    '70BDFC',
    'D2A5FC',
    'FC896E',
];
export type State = {
    selectedItem: { name: string, value: number };
};

const CustomLabel = ({ viewBox, value1, value2 }: any) => {
    const { cx, cy } = viewBox;
    const styler = { fill: '#222427',  textAnchor: 'middle', dominantBaseline: 'central', fontWeight: 'bold' };
    return (
        <Fragment>
            <text x={cx} y={cy - 15}  {...styler} fontSize={20}>
                {value1}
            </text>
            <text x={cx} y={cy + 25}  {...styler} fontSize={30}>
                {value2}
            </text>
     </Fragment>
    );
};
@observer
export default class PieChartComponent extends Component<Props, State> {
    state: State = {
        selectedItem: null,
    };

    getData() {
        return Object.keys(this.props.data).map(x => ({ name: x,  value: this.props.data[x] }));
    }

    getColor(index: number) {
        const color = colors[index % colors.length];
        return `#${color}`;
    }

    onMouseEnter = (e) => {
        this.setState({ selectedItem: e });
    }
    onMouseExit = () => {
        this.setState({ selectedItem: null });
    }
    formatPercent = (number: number) => {
        if (number) {
            return `${Math.round(number * 100)}%`;
        }
    }

    render() {
        const isMobile = appState.screenWidth < 400;
       return (
           <div className={style.container}>
               <ResponsiveContainer width={'100%'} height={'100%'}>
                    <PieChart>
                        <Pie
                            innerRadius={120}
                            data={this.getData()}
                            isAnimationActive={true}
                            dataKey={'value'}>
                            {this.getData().map((entry, i) => <Cell key={entry.name} fill={this.getColor(i)} onMouseEnter={() => this.onMouseEnter(entry)} onMouseLeave={this.onMouseExit}/>)}
                            <Label
                                offset={0}
                                position={'center'}
                                content={
                                    <CustomLabel
                                        value1={this.state.selectedItem?.name || this.props.placeholder1}
                                        value2={this.formatPercent(this.state.selectedItem?.value) || this.props.placeholder2}/>
                                }></Label>
                        </Pie>
                        { !isMobile ?
                            (<Legend key={'name'} layout={'vertical'} verticalAlign={'middle'} align={'right'} />) :
                            null
                            // (<Legend key={'name'} layout={'vertical'} verticalAlign={'bottom'} align={'center'} />)
                        }
                    </PieChart>
                </ResponsiveContainer>
            </div>
       );
    }
}
