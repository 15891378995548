import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './caseComposition.style.scss';
import { appState } from '@state/index';
import InfoCardComponent from '@components/infoCard/infoCard.component';
import PieChartComponent from '@components/pieChart/pieChart.component';

export type Props = {

};
@observer
export default class CaseCompositionComponent extends Component<Props> {
    renderInfo = () => (
        <Fragment>
                <h4>Once the process is complete, the securities that make up the portfolio will be presented</h4>
                The case was built using the Nobel Prize-winning methodology, which was unique to Israel by our experts. <br/>
                We have selected the specific products in each investment channel according to their performance and costs from the selection of products available in the Israeli market. <br/>
                An optimal portfolio combines high dispersion and low costs to generate maximum return over time at a limited risk level. mean-variance optimization methodology <br/>
                The use of index products enables high asset spreading through a small number of holdings <br/>
                <strong>and low management costs</strong><br />
                The portfolio is built in several stages. <br/>
                The optimization model produces an efficient portfolio curve, which shows the range of optimal portfolios for each risk level and is based on the Markovich model, a widely recognized model that is widely used.<br/>
                The scenarios are then applied to stress scenarios (such as the 2008 landslide, the high-tech crisis of 2000) and, at the last stage.
                extension model use model can be found
        </Fragment>
    )
    render() {
        const formatted = new Intl.NumberFormat('he-HE', { style: 'currency', currency: 'USD' }).format(appState.fileData.investmentAmt);
        return (
            <div className={style.container}>
                        <InfoCardComponent
                            title={'Case Composition'}
                            disclaimer={'The composition of the portfolio presented and its distribution to investment channels does not constitute a commitment to the actual investment structure in the client portfolio. The data is presented for illustrative purposes only. The aforementioned does not include advice or investment marketing, or a substitute for consulting / marketing, or offering to buy securities.'}
                            info={this.renderInfo}>
                                <PieChartComponent
                                    placeholder1={'Total Investment'}
                                    placeholder2={formatted}
                                    title={'test'}
                                    data={appState.fileData.getStocks()}></PieChartComponent>
                            </InfoCardComponent>
                    </div>
        );
    }
}
