import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './stocksMarket.style.scss';
import { appState } from '@state/index';
import LineChartComponent from '@components/lineChart/lineChart.component';
import InfoCardComponent from '@components/infoCard/infoCard.component';

export type Props = {

};
@observer
export default class StocksMarketComponent extends Component<Props> {
    renderInfo = () => {
        return (
            <Fragment>
                <div>Hello world</div>
            </Fragment>
        );
    }
    render() {
        return (
            <div className={style.container}>
                <InfoCardComponent
                    title={'Bag Perfomance'}
                    disclaimer={'Lorem ipsum dolor sit emet'}
                    info={this.renderInfo}>
                    <LineChartComponent />
                </InfoCardComponent>
            </div>
        );
    }
}
