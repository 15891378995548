import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './leadPopup.style.scss';
import { appState } from '@state/index';
import ModalComponent from '@components/modal/modal.component';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import isEmail from 'sane-email-validation';
import { RotateSpinner } from 'react-spinners-kit';

export type Props = {

};
export type State = {
    isOpen: boolean;
    name: string;
    phone: string;
    email: string;

    nameValid: boolean;
    phoneValid: boolean;
    emailValid: boolean;
    isSubmitting: boolean;
    didSubmit: boolean;
};
@observer
export default class LeadPopupComponent extends Component<Props, State> {
    state: State = {
        isOpen: true,
        name: '',
        phone: '',
        email: '',
        nameValid: false,
        phoneValid: false,
        emailValid: false,
        isSubmitting: false,
        didSubmit: false,
    };

    checkValid() {
        this.setState({
            emailValid: isEmail(this.state.email),
            phoneValid: this.state.phone.length > 7,
            nameValid: this.state.name.length > 2,
        });
    }

    isFormValid() {
        return this.state.nameValid && this.state.emailValid && this.state.phoneValid;
    }

    onEmailChange = (e) => {
        if (!this.state.isSubmitting) {
            this.setState({ email: e.target.value }, () => this.checkValid());
        }
    }
    onNameChange = (e) => {
        if (!this.state.isSubmitting) {
            this.setState({ name: e.target.value }, () => this.checkValid());
        }
    }
    onPhoneChange = (e) => {
        if (!this.state.isSubmitting) {
            this.setState({ phone: e }, () => this.checkValid());
        }
    }
    onSubmit = async () => {
        this.setState({ isSubmitting: true });
        await new Promise((resolve) => setTimeout(() => {
            resolve();
        }, 1000));
        this.setState({ isSubmitting: false, isOpen: false, didSubmit: true });
    }
    closeForm = () => {
        if (this.state.didSubmit) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        return (
            <ModalComponent
                isOpen={this.state.isOpen}
                onRequestClose={this.closeForm}
                hideExit={true}>
                <div className={style.container}>
                    <div className={style.title}>Get More Information</div>
                    <div className={style.form}>
                        <div className={style.fieldContainer}>
                            <div className={style.fieldLabel}>Name</div>
                            <input className={cls(style.fieldInput, { [style.invalid]: this.state.name.length !== 0 && !this.state.nameValid })} onChange={this.onNameChange} value={this.state.name} />
                            {/* {this.state.name.length !== 0 && !this.state.nameValid && (<div>{'Please enter a valid name'}</div>)} */}
                        </div>
                        <div className={style.fieldContainer}>
                            <div className={style.fieldLabel}>Email</div>
                            <input className={cls(style.fieldInput, { [style.invalid]: this.state.email.length !== 0 && !this.state.emailValid })} onChange={this.onEmailChange} value={this.state.email} />
                            {/* {this.state.email.length !== 0 && !this.state.emailValid && (<div>{'Please enter a valid email'}</div>)} */}
                        </div>
                        <div className={style.fieldContainer}>
                            <div className={style.fieldLabel}>Phone</div>
                            <PhoneInput onChange={this.onPhoneChange} value={this.state.phone} />
                            {/* {this.state.phone.length !== 0 && !this.state.phoneValid && (<div>{'Email is invalid'}</div>)} */}
                        </div>
                        <div className={style.ctaContainer}>
                            <button className={cls(style.cta, { [style.disabled]: !this.isFormValid() })} onClick={this.onSubmit} disabled={!this.isFormValid()}>
                                {this.state.isSubmitting ? <RotateSpinner size={30} /> : 'Apply'}
                            </button>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        );
    }
}
