import React, { Component, ReactElement } from 'react';
import { observer, inject } from 'mobx-react';
import cls from 'classnames';
import style from './modal.style.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { clear } from './assets';
export type Props = {
    isOpen: boolean;
    onRequestClose: () => any;
    children: ReactElement;
    hideExit?: boolean;
};

@observer
export default class ModalComponent extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onExit = () => {
        this.props.onRequestClose();
    }
    onBackdropClicked = (e) => {
        const classList = Array.from(e.target.classList);
        if (classList.includes(style.backdrop)) {
            this.onExit();
        }
    }
    onKeyboardClick = (e) => {
        if (this.props.isOpen) {
            if (e.keyCode === 27) { // Esc Key
                this.onExit();
            }
        }
    }
    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.addEventListener('keydown', this.onKeyboardClick);
        }
    }
    static getDerivedStateFromProps(props: Props) {
        if (typeof window !== 'undefined') {
            if (props.isOpen) {
                window.document.body.style.overflow = 'hidden';
            } else {
                window.document.body.style.overflow = '';
            }
        }
        return null;
    }
    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('keydown', this.onKeyboardClick);
        }
    }
    render() {
        return (
            <AnimatePresence>
                {this.props.isOpen ? (
                    <motion.div
                        onClick={this.onBackdropClicked}
                        className={style.backdrop}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.05, delay: 0 }}
                        animate={{ opacity: 1 }} >
                        <motion.div
                            className={style.modal}
                            exit={{ opacity: 0, transform: 'translateY(-30%)' }}
                            initial={{ opacity: 0, transform: 'translateY(-30%)' }}
                            animate={{ opacity: 1, transform: 'translateY(0%)' }}
                            transition={{ duration: 0.2, delay: 0.3 }}>
                            {this.props.hideExit ? null : (
                                <div className={style.exitContainer} onClick={this.onExit}>
                                    <img className={style.exitButton} src={clear} />
                                </div>
                            )}
                            <div className={style.content}>
                                {this.props.children}
                            </div>
                        </motion.div>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        );
    }
}
