import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './investReasons.style.scss';
import { appState } from '@state/index';
import {
    ekronotIcon1,
    ekronotIcon2,
    ekronotIcon3,
    ekronotIcon4,
    ekronotIcon5,
    ekronotIcon6,
} from './assets';

export type Props = {

};
export type ItemProps = {
    title: string;
    content: string;
    image: string;
};
const Item = (props: ItemProps) => (
    <div className={style.item}>
        <div className={style.imgContainer}>
            <img src={props.image} className={style.image}/>
        </div>
        <div className={style.title}>{props.title}</div>
        <div className={style.content}>{props.content}</div>
    </div>
);
@observer
export default class InvestReasonsComponent extends Component<Props> {
    render() {
        return (
            <div className={style.container}>
                <Item
                    title={'Smart investment'}
                    content={'The Nobel Prize-winning investment model has been updated and tailored specifically for the Israeli investor through advanced technology.'}
                    image={ekronotIcon1}
                    />
                <Item
                    title={'Premium service for every investor'}
                    content={'Thanks to our advanced technology, you can get the same professionalism and service we offer to large investors.'}
                    image={ekronotIcon2}
                    />
                <Item
                    title={'Fair management fees'}
                    content={'Our model enables you to manage investments in the most professional way, at fair prices. Our management fees are among the lowest in the market and in addition, we are exempt from VAT.'}
                    image={ekronotIcon3}
                    />
                <Item
                    title={'Advanced tools'}
                    content={'We will provide you with advanced tools through which you can easily understand what is going on in your portfolio and how your investment is moving towards the goals you set.'}
                    image={ekronotIcon4}
                    />
                <Item
                    title={'Objective management'}
                    content={'We are committed to choosing the best and most suitable investment products for your portfolio right from the start, without any conflict of interest.'}
                    image={ekronotIcon5}
                    />
                <Item
                    title={'The money stays in your bank'}
                    content={'We\'ll manage the investments in any bank you want without having to spend or transfer funds. And of course, your money is available for withdrawal.'}
                    image={ekronotIcon6}
                    />
            </div>
        );
    }
}
