import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './filePage.style.scss';
import { polygons } from './assets';
import { appState } from '@state/index';
import InfoCardComponent from '@components/infoCard/infoCard.component';
import CaseCompositionComponent from '@components/information/caseComposition/caseComposition.component';
import HeaderSliderComponent from '@components/headerSlider/headerSlider.component';
import StocksMarketComponent from '@components/information/stocksMarket/stocksMarket.component';
import InvestReasonsComponent from '@components/information/investReasons/investReasons.component';
import MoreInfoComponent from '@components/information/moreInfo/moreInfo.component';
import LeadPopupComponent from '@components/leadPopup/leadPopup.component';

export type Props = {

};
@observer
export default class FilePageComponent extends Component<Props> {
    onRangeChange = (e: number) => {
        appState.fileData.investmentDuration = e;

    }
    onInvestmentChange = (e: number) => {
        appState.fileData.investmentAmt = e;
    }
    render() {
        return (
            <div className={style.container}>
                {/* <img className={style.polygonsOverlay} src={polygons} /> */}
                <div className={style.heading}>
                    <div className={style.profileScore}>
                        <div className={style.content}>
                            <div className={style.h3}>According to the information you provided and the model results, the risk profile is right for you:</div>
                            <div className={style.h1}>{appState.fileData.getScoreText()} Profile ({appState.fileData.getSolidity()}/7)</div>
                        </div>
                    </div>
                    <div className={style.profileInfo}>
                        <div className={style.content}>
                            <div className={style.h3}>We have compiled all the details for you to illustrate your risk profile and support your decision making. You also have the option to save and forward the offer.</div>
                            <div className={style.h4}>You can change the amount and scope of the investment here, or answer the questionnaire again and see the effect on the offer.</div>
                        </div>
                    </div>
                </div>
                <div className={style.stickyHeader}>
                    <div className={style.pageWidth}>
                        <div className={style.stickyGrid}>
                            <div className={style.left}>
                                <HeaderSliderComponent
                                    onChange={this.onRangeChange}
                                    preText={'Range'}
                                    step={1}
                                    postText={'Years'}
                                    type={'number'}
                                    value={appState.fileData.investmentDuration}
                                    min={1}
                                    max={appState.fileData.getMaxDuration()}
                                />
                            </div>
                            <div className={style.seperator}></div>
                            <div className={style.right}>
                                <HeaderSliderComponent
                                    step={1000}
                                    onChange={this.onInvestmentChange}
                                    preText={'Investment'}
                                    type={'currency'}
                                    min={250}
                                    max={appState.fileData.getMaxAmt()}
                                    value={appState.fileData.investmentAmt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.pageWidth}>
                    <div className={style.cardMargin}>
                        <CaseCompositionComponent />
                    </div>
                    <div className={style.cardMargin}>
                        <StocksMarketComponent />
                    </div>
                    <div className={style.cardMargin}>
                        <InvestReasonsComponent />
                    </div>
                    <div className={style.cardMargin}>
                        <MoreInfoComponent />
                    </div>
                    <LeadPopupComponent />
                </div>
            </div>
        );
    }
}
