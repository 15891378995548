import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './lineChart.style.scss';
import { appState } from '@state/index';
import { ResponsiveContainer, XAxis, Tooltip, CartesianGrid, Bar, BarChart, YAxis } from 'recharts';

const stocks = [
    { name: 2012, value: 100 },
    { name: 2013, value: 90 },
    { name: 2014, value: 70 },
    { name: 2015, value: 30 },
    { name: 2016, value: 12 },
    { name: 2017, value: 20 },
    { name: 2018, value: 50 },
    { name: 2019, value: 64 },
    { name: 2020, value: 81 },
];
export type Props = {

};
@observer
export default class LineChartComponent extends Component<Props> {
    render() {
        return (
            <div className={style.container}>
                <ResponsiveContainer width={'100%'} height={'100%'}>
                <BarChart data={stocks}>
                    <Tooltip />
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Bar dataKey="value" fill="#458DF7" />
                </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
