import React, { Component, ReactElement } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './infoCard.style.scss';
import { appState } from '@state/index';

export type Props = {
    title: string;
    disclaimer: string;
    info: () => ReactElement;
};
export type State = {
    readMoreOpen: boolean;
};
@observer
export default class InfoCardComponent extends Component<Props, State> {
    state: State = {
        readMoreOpen: false,
    };
    onReadMore = () => {
        this.setState({ readMoreOpen: true });
    }
    onCloseReadMore = () => {
        this.setState({ readMoreOpen: false });
    }
    render() {
        const { readMoreOpen } = this.state;
        const openStyle = { [style.open]: readMoreOpen };
        return (
            <div className={style.container}>
                <div className={style.contentHolder}>
                    {/* <div className={cls(style.explainPlaceholder)}></div>
                    <div className={cls(cls(style.explainContainer, openStyle))}>
                        <div className={style.explainContent}>
                            <div className={style.title}>{ this.props.title}</div>
                            <div className={cls(style.explainContentInfo, openStyle)}>
                                { this.props.info() }
                            </div>
                            <div className={cls(style.readMore, openStyle)} onClick={this.onReadMore}>Read More</div>
                            <div className={cls(style.closeReadMore, openStyle)} onClick={this.onCloseReadMore}>{''}</div>
                        </div>
                    </div> */}
                    <div>{ this.props.children }</div>
                </div>
                {/* <div className={style.explainBottom}>
                    { this.props.disclaimer }
                </div> */}
            </div>
        );
    }
}
