import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import { appState } from '@state/index';
import PageLayoutComponent from '@components/layout/pageLayout.component';
import PieChartComponent from '@components/pieChart/pieChart.component';
import InfoCardComponent from '@components/infoCard/infoCard.component';
import FilePageComponent from '@components/filePage/filePage.component';

export type Props = {

};
@observer
export default class FilePage extends Component<Props> {
    componentDidMount() {
        appState.didFinishStages = true;
    }
    render() {
        return (
            <PageLayoutComponent>
                <FilePageComponent />
            </PageLayoutComponent>
        );
    }
}
