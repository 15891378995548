import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import style from './headerSlider.style.scss';
import { appState } from '@state/index';
import SliderComponent from '@components/slider/slider.component';

export type Props = {
    type: 'number' | 'currency',
    preText: string;
    postText?: string;
    min: number;
    max: number;
    value?: number;
    step: number;
    onChange?: (value: number) => any;
};
@observer
export default class HeaderSliderComponent extends Component<Props> {
    render() {
        const formatted = new Intl.NumberFormat('he-HE', { style: 'currency', currency: 'USD' }).format(this.props.value);
        const value = this.props.type === 'currency' ? formatted : this.props.value;
        return (
            <div className={style.container}>
                <div className={style.text}>
                    <div className={style.preText}>{this.props.preText}</div>
                    <input readOnly={true} value={value} className={style.input} hidden={true} />
                </div>
                <div className={style.textMobile}>
                    {this.props.preText} {value} {this.props.postText}
                </div>
                <div className={style.progress}>
                    <SliderComponent
                        onChange={this.props.onChange}
                        value={this.props.value}
                        step={this.props.step}
                        min={this.props.min}
                        max={this.props.max} />
                </div>
                <div className={style.postText}>{this.props.postText}</div>
            </div>
        );
    }
}
